.carousel-item {
  max-height: 80vh;
  height: calc (100% - 20vh);
}

.height-45 {
  max-height: 45vh;
  height: calc (100% - 55vh);
}

.slide-container {
  width: 100%;
  margin: auto;
  max-height: 80vh;
  height: calc (100% - 20vh);
}
.slide-container > :first-child {
  margin-bottom: -20px;
}
.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
.each-fade {
  display: flex;
}

.banner80 {
  max-height: 80vh;
  width: 100%;
}
.each-fade .image-container {
  width: 100%;
  overflow: hidden;
  max-height: 80vh;
  height: calc (100% - 20vh);
}

.each-fade .image-container img {
  width: 100%;
}
.indicators div {
  display: block;
  /* margin: -30px; */
}

.carousel-info {
  text-align: center;
  position: absolute;
  top: 60%;
  left: 12%;
  z-index: 2;
  box-sizing: border-box;
}

.carousel-info h3 {
  color: #fff;
  font-weight: bold;
  text-shadow: 0px 0px 3px rgb(70, 69, 69);
  text-transform: capitalize;
  font-size: 3.5rem;
}

.carousel-info p {
  color: #fff;
  text-shadow: 0px 0px 3px #000;
  margin-top: 12px;
  letter-spacing: 5px;
  font-weight: 600;
  text-transform: uppercase;
}
