.banner-text {
  color: white;
  font-weight: bold;
  text-shadow: 0px 0px 2px rgb(70, 69, 69);
  position: absolute;
  top: 35%;
  left: 35%;
}

.banner-text h3 {
  font-size: 3rem;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
}

.banner-text p {
  text-transform: uppercase;
  text-align: center;
}

.carousel-info {
  text-align: center;
  position: absolute;
  top: 60%;
  left: 12%;
  z-index: 2;
  box-sizing: border-box;
}

.carousel-info h3 {
  color: #fff;
  font-weight: bold;
  text-shadow: 0px 0px 3px rgb(70, 69, 69);
  text-transform: capitalize;
  font-size: 3.5rem;
}

.carousel-info p {
  color: #fff;
  text-shadow: 0px 0px 3px #000;
  margin-top: 12px;
  letter-spacing: 5px;
  font-weight: 600;
  text-transform: uppercase;
}
