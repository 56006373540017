/*=-footer-=*/
footer {
  color: #ccc;
  background-attachment: fixed;
  background-color: #222;
  background-size: cover;
  background-position: bottom;
}
footer p {
  color: #ccc;
}
footer a {
  color: #ccc;
}
footer a:hover {
  color: #fff;
  text-decoration: none;
}
.social-icon {
  width: 40px;
  margin-top: -75px;
}
.margin-0-auto {
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .social-icon {
    margin-top: 0;
  }
}
